import React from 'react'
import Layout from '../components/Layout'
import {useStaticQuery, graphql} from 'gatsby'
import Img from 'gatsby-image'
import Gallery from '../components/Gallery'
import {Seo} from '../components/Seo'

const Galeria = () => {
  return (
    <Layout>
      <Seo title='Galeria | Modelowanie Sylwetki' />

      <Gallery />
    </Layout>
  )
}

export default Galeria
