import React from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import Img from 'gatsby-image'
import SimpleReactLightbox from 'simple-react-lightbox'
import {SRLWrapper} from 'simple-react-lightbox'

const Gallery = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          name: {
            nin: ["logo-modelowanie-sylwetki-small", "logo-modelowanie-sylwetki-large"]
          }
        }
      ) {
        edges {
          node {
            base
            publicURL
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  `)

  const options = {
    settings: {
      transitionSpeed: 900,
    },
    buttons: {
      showAutoplayButton: false,
      showDownloadButton: false,
      showThumbnailsButton: false,
      showFullscreenButton: false,
    },
    caption: {
      showCaption: false,
    },
  }

  return (
    <div className='gallery-container'>
      <h1>Galeria</h1>
      <div className='gallery'>
        <SimpleReactLightbox>
          <SRLWrapper options={options}>
            {data.allFile.edges.map((image, key) => (
              <a href={image.node.publicURL}>
                <Img
                  key={key}
                  className='image-item'
                  fluid={image.node.childImageSharp.fluid}
                  alt={image.node.base.split('.')[0]}
                />
              </a>
            ))}
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
    </div>
  )
}

export default Gallery
